import { NgModule } from '@angular/core';
import {CarouselModule} from 'ngx-bootstrap';
import {Router} from "@angular/router";
import { NewHomeRoutingModule } from './new-home-routing.module';
import { NewHomeComponent } from './new-home.component';
import { SharedModule } from '@app/shared/shared.module';
import { CommonModule ,ViewportScroller} from '@angular/common';

@NgModule({
  declarations: [NewHomeComponent],
  imports: [
    CommonModule,
    NewHomeRoutingModule,SharedModule, CarouselModule
  ]
})
export class NewHomeModule {

  constructor(router: Router, viewportScroller: ViewportScroller) {

  }

 }
