import { Component, OnInit, Input } from '@angular/core'
import { environment } from 'environments/environment'
import {
  Router,NavigationEnd
} from '@angular/router'
import { GoogleAnalyticsService } from '@app/services/google-analytics.service'


@Component({
  selector: 'mytribu-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() description: any = ''
  @Input() background: any = ''
  @Input() text: any = ''
  @Input() color: any = '#e2b00d'
  @Input() set icon(value) {
    this._icon = this.url + value
  }
  _icon: any = ''
  url: any = environment.urlImage

  constructor(public router: Router,private gaService: GoogleAnalyticsService){}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.gaService.sendEvent('page_view', { page_path: event.urlAfterRedirects });
      }
    });
    this.gaService.startTime = Date.now(); // Démarrer le suivi
  }
  ngOnDestroy() {
    this.gaService.trackTimeSpent();

  }
}
