import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "@app/services/auth.service";
import { Router } from "@angular/router";
import { ChartComponent } from "@app/main/chart/chart.component";
import { TabsChartComponent } from "@app/main/chart/tabs-chart/tabs-chart.component";
import { OffresService } from "@app/services/offres.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';
import { MonCompteService } from "@app/services/mon-compte.service";
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
  selector: "mytribu-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"]
})
export class MainHeaderComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    public auth: AuthService,
    public router: Router, public MonCompte: MonCompteService,
    public home: OffresService,private cookies: CookieService,private gaService: GoogleAnalyticsService
  ) {}
  client: any = {};
  offres: any;
  TypesOffres: any;
  id: any;
  accueill: any = [];

  ngOnInit() {
    this.client = this.auth.getClient();
    this.getTypeOffres();
   // this.href = this.router.url;
    //let result = this.href.includes("/espaceEtablissement");
    //if(result === false){   //espace client
      this.Accueil();
    //} 
  }

  deconnexion() {
    let clientNonInscri = localStorage.getItem("clientNonInscri");
    if (!clientNonInscri) {
      localStorage.clear();
    }

    this.router.navigate(["connexion"]);
  }

  GoTo() {
    this.router.navigate(["espaceClient"]);
  }

  GoHome() {
    this.router.navigate(["home"]);
  }

  getTypeOffres() {
    this.home
      .getTypeOffres()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.TypesOffres = res;
        if (!this.id && res[0]) {
          this.id = res[0].id;
        }
        for (let index = 0; index < this.TypesOffres.length; index++) {
          const el = this.TypesOffres[index];
        }
      });
  }

  Accueil() {
    this.MonCompte.getAccueil()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.accueill = res;
        localStorage.setItem('couvertureAccueil' , res.couvertureAccueil)
        localStorage.setItem('lienAccueil' , res.couvertureAccueilLien)
      })
  }
  trackButtonClick(buttonName: string) {
    this.gaService.sendEvent('button_click', { button_name: buttonName });
  }
}
