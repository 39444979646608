import { Component, OnInit, Input } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AuthService } from '@app/services/auth.service'
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: 'mytribu-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() id: any
  pass: any = {
    password: '',
    confirmePassword: ''
  }
  errormessage: any = ''
  success: any = ''
  showPassword: any = false
  showConfirmePassword: any = false
  passPattern =
    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'

  constructor(public auth: AuthService, public route: ActivatedRoute,public router: Router) {}
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id") || "";
      console.log("Code lien");
      console.log(this.id);
    });
  }
  private _unsubscribeAll: Subject<any> = new Subject()

  Reset(pass) {

    console.log(pass);
    console.log(this.id);
    let resetpassword: any = {
      password: this.pass.password
    }
    console.log(resetpassword);
    if (this.pass.password != '' && this.pass.confirmePassword != '') {
      this.auth
        .resetPassword(this.id, resetpassword)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          res => {
            this.success = 'Votre mot de passe a été modifié avec succès'
            let self = this
            setTimeout(() => {
              this.success = 'Votre mot de passe a été modifié avec succès'
              self.success = ''
              this.router.navigate(['connexion'])
            }, 5000)
          },
          MsgError => {
            if (
              MsgError.err.error &&
              MsgError.err.error.message == 'Client not found.'
            ) {
              this.errormessage = `Le lien a expiré. S'il vous plaît essayer à nouveau.`
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == 'Client introuvable.'
            ) {
              this.errormessage = `Le lien a expiré. S'il vous plaît essayer à nouveau.`
            }
            else if (
              MsgError.err.error &&
              MsgError.err.error.message == 'password exist.'
            ) {
              this.errormessage = `Désolé ! Vous ne pouvez pas réutiliser un ancien mot de passe. Réessayez avec un nouveau`
            }
            else if  (
              MsgError.err.error &&
              MsgError.err.error.message == 'Mot de passe existe.'
            ) {
              this.errormessage = `Désolé ! Vous ne pouvez pas réutiliser un ancien mot de passe. Réessayez avec un nouveau`
              setTimeout(() => {
                this.errormessage = '' 
              }, 5000)
            }
            this.pass.password = ''
            this.pass.confirmePassword = ''
          }
        )
    }/*  else {
      this.errormessage =
        this.pass.password == ''
          ? 'Veuillez remplir tous les champs requis.'
          : 'Veuillez remplir tous les champs requis.'
    } */
  }
}
