import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { HttpClient } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class OffresService {
  url: any = environment.url
  constructor(public http: HttpClient, private auth: AuthService) {}

  /*************** Espace Client *****************/

  //*** TypeOffres */
  getTypeOffres(): Observable<any> {
    return this.http.get(this.url + 'getOffresTypes')
  }
  /**************************/

  //*** Home */
  getHome(idTypeOffre): Observable<any> {
    let params = { idTypeOffre: idTypeOffre }
    return this.http.get(this.url + 'home', { params })
  }
  /**************************/

  //*** PageOffre */
  getPageOffre(idOffre): Observable<any> {
    return this.http.get(this.url + 'pageOffre/' + idOffre)
  }
  /**************************/
 //*** Pagedynamic */
 /*getpagesstatic(name): Observable<any> {
  return this.http.get(this.url + 'pagesstatic/' + name)
}*/
/**************************/
  //*** Banners Offres */
  getBanners(idOffre): Observable<any> {
    return this.http.get(this.url + 'banners/' + idOffre)
  }

  //*** Banners TypesOfres*/
  getBannersTypesOfres(idTypeOffre): Observable<any> {
    return this.http.get(this.url + 'bannersTypesOffres/' + idTypeOffre)
  }

    //*** Page statique */

    getPageStatique(segmentStat): Observable<any> {

      return this.http.get(this.url + 'pageStatique/' + segmentStat)
  
    }
  
     //*** Page generique */
  
     getPageGenerique(segmentGen): Observable<any> {
  
      return this.http.get(this.url + 'pageGenerique/' + segmentGen)
  
    }
}
