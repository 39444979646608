import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core'
import { Router } from '@angular/router'
import { DynamicScriptLoaderService } from '@app/services/dynamic-script-loader.service'
import { environment } from 'environments/environment'

declare const jssor_1_slider_init: any

@Component({
  selector: 'mytribu-simple-slider',
  templateUrl: './simple-slider.component.html',
  styleUrls: ['./simple-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSliderComponent implements OnInit {
  @Input() Slider: any = []
  subs: any
  slideIndex: any = 0
  lastindex: any = 0
  url: any = environment.urlImage

  constructor(
    public router: Router,
    public dynamicScriptLoader: DynamicScriptLoaderService
  ) {}

  ngOnInit() {
    this.startplay()
  }

  startplay() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.subs = this.dynamicScriptLoader
      .load('SliderLogin')
      .then(data => {
        this.play()
      })
      .catch(error => console.log(error))
  }
  play() {
    setTimeout(() => {
      new jssor_1_slider_init()
    }, 100)
  }
}
