import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SafePipe } from '@app/Pipes/safe.pipe'

@NgModule({
  declarations: [SafePipe,],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  exports: [SafePipe, ]
})
export class PipesModule {}
