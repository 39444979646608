import { Component  } from '@angular/core'
import {
  Router,NavigationEnd
} from '@angular/router'
import { SplashScreenService } from './services/splash-screen.service'
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent { 
  title = 'myTribu'
  public isShowingRouteLoadIndicator: boolean
  location: any = ''
  private routerSubscription: Subscription;

  /**
   * Constructor
   *
   * @param {SplashScreenService} SplashScreenService
   */
  constructor(
    public router: Router,
   // private timeTrackingService: TimeTrackingService,
   private gaService: GoogleAnalyticsService,
    // tslint:disable-next-line:no-shadowed-variable
    private SplashScreenService: SplashScreenService,@Inject(DOCUMENT) private document: Document
  ) {

  }

  // tab: number
  ngOnInit() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.gaService.sendEvent('page_view', { page_path: event.urlAfterRedirects });
      }
    });
    this.gaService.startTime = Date.now(); // Démarrer le suivi

  }
    // Exemple d'utilisation pour envoyer un événement
    onButtonClick() {
      this.gaService.sendEvent('button_click', { button_name: 'my_button' });
    }
  ngOnDestroy() {
    this.gaService.trackTimeSpent();

  }

  /*scrollToTop(): void {
    // scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }*/
}
