import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RouterServiceService } from '@app/services/router-service.service';
import { AuthService } from '@app/services/auth.service';
import { Subject } from 'rxjs';
import { TabsetComponent } from 'ngx-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mytribu-testpages',
  templateUrl: './testpages.component.html',
  styleUrls: ['./testpages.component.scss']
})
export class TestpagesComponent implements OnInit {
  @ViewChild("tabset") tabset: TabsetComponent;
  public pageContent: any = null;
  private _unsubscribeAll: Subject<any> = new Subject()
  page:any
  url: any = '';
  mySubscription: any;
  head:any=false
  segmentPath = '';
  constructor(public auth: AuthService, public activeRoute: ActivatedRoute ,

    private route:Router, private routerService: RouterServiceService) {this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.route.navigated = false;
      }
    });
   this.url = route.url; 
  }

  ngOnInit() {
    console.log('test page');
   /* this.pageContent = JSON.parse(localStorage.getItem('pages'));  
    console.log(localStorage.getItem('pages') ); 
    this.navigateGeneriquePage(this.pageContent)*/
      //localStorage.setItem('pages', JSON.stringify(this.pageContent));
    //this.route.navigate(['/home/' + this.pageContent.segment]);

        this.pageContent= null;
    this.getStaticPage()

    this.auth.getToken().subscribe(res => {
      if (res && Object.keys(res).length > 0) {
      this.head =
          localStorage.getItem('token') && localStorage.getItem('token') != ''
      }
    })
   
  }

  navigateGeneriquePage(item){

    console.log("item")
    console.log(item)
    localStorage.setItem('pages', JSON.stringify(item));
    this.route.navigate(['/home/' + item.segment]);
    
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    

  }
  selectTab(tabId?) {
    let id = tabId.id ? tabId.id : 0;
    this.tabset.tabs[id].active = true;

  }
getStaticPage() {
    let path =  this.url;
    this.pageContent= null;
    let last = path.substring(path.lastIndexOf("/") + 1, path.length);
   console.log('path',path)
   console.log('last',last)
    this.segmentPath = JSON.parse(localStorage.getItem('pages'))
    this.auth
      .StaticPage(last)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        console.log(res, 'res')
        this.pageContent = res
        console.log('his.pageContent)',this.pageContent);
      })
  }

}