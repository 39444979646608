import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { HttpClient } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MonCompteService {
  url: any = environment.url
  constructor(public http: HttpClient, private auth: AuthService) {}

  /*************** MonCompte Management *****************/

  //*** Accueil */
  getAccueil(): Observable<any> {
    return this.http.get(this.url + 'MC/client/accueil')
  }
  /**************************/

  //*** Preferences */
  getPreferences(): Observable<any> {
    return this.http.get(this.url + 'MC/client/preferences')
  }
  getNewsletters(): Observable<any> {
    return this.http.get(this.url + 'MC/client/newsletters')
  }

  AttachPreferences(preferences): Observable<any> {
    let body = preferences
    return this.http.post(this.url + 'MC/client/attach/preferences', body)
  }

  AttachNewsletters(object): Observable<any> {
    let body = object
    return this.http.post(this.url + 'MC/client/attach/newsletters', body)
  }
  /**************************/

  //*** Coordonnees*/
  getCoordonnees(): Observable<any> {
    return this.http.get(this.url + 'MC/client/coordonnees')
  }

  updateCoordonnees(Coordonnees, password, confirmePassword): Observable<any> {
    let body = {
      identifiant: Coordonnees.identifiant,
      firstName: Coordonnees.firstName,
      lastName: Coordonnees.lastName,
      dateNaissance: Coordonnees.dateNaissance,
      email: Coordonnees.email,
      telephone: Coordonnees.telephone,
      adresse: Coordonnees.adresse,
      codePostal: Coordonnees.codePostal,
      ville: Coordonnees.ville,
      pays: Coordonnees.pays,
      idCivilite: Coordonnees.idCivilite,
      password: password,
      confirmePassword: confirmePassword
    }
    return this.http.put(this.url + 'MC/client/update/coordonnees', body)
  }
  /**************************/

  //*** Abonnement*/
  getAbonnement(): Observable<any> {
    return this.http.get(this.url + 'MC/client/abonnement')
  }

  updateAbonnement(Coordonnees): Observable<any> {
    let body = {
      firstName: Coordonnees.firstName,
      lastName: Coordonnees.lastName,
      email: Coordonnees.email,
      idCivilite: Coordonnees.idCivilite
    }
    return this.http.put(this.url + 'MC/client/update/abonnement', body)
  }
  /**************************/

  //*** Commandes*/
  getCommandes(): Observable<any> {
    return this.http.get(this.url + 'MC/client/commandes')
  }
  /**************************/

  //*** Reset Password*/
  ResetPassword(email): Observable<any> {
    let body = email
    return this.http.post(this.url + 'MC/client/reset/password', body)
  }
  /**************************/

  //*** Civilites*/
  getCivilites(): Observable<any> {
    return this.http.get(this.url + 'MC/civilites')
  }
  /**************************/

  //*** codePromo Renouvelement*/
  codePromoRenouvelement(codePromo): Observable<any> {
    let body = codePromo
    return this.http.post(this.url + 'MC/renouvelement/check/codePromo', body)
  }
  /**************************/

  //*** codePromo Inscription */
  codePromoInscription(codePromo): Observable<any> {
    let body = codePromo
    return this.http.post(this.url + 'MC/inscription/check/codePromo', body)
  }
  /**************************/

  //**** Inscription */
  Inscription(login): Observable<any> {
    let body = login
    return this.http.post(this.url + 'MC/client/inscrire', body)
  }

  InscriptionAdherent(login): Observable<any> {
    const body = login;
    return this.http.post(this.url + "MC/etablissement/inscrire", body);
  }
  /**************************/

  //**** Product Price */
  ProductPrice(): Observable<any> {
    return this.http.get(this.url + 'MC/prixProduit')
  }
  /**************************/

  //**** paiement banque */
  paiementBanque(banque): Observable<any> {
    let body = banque
    return this.http.post(this.url + 'paiementBanque', body)
  }
  sendsMailsHome(demandeInfo) {
    const body = {
      nom: demandeInfo.nom,
      prenom: demandeInfo.prenom,
      tel: demandeInfo.tel,
      email: demandeInfo.email,
     
    };
    return this.http.post(this.url + "sendsMailsFO", body);
  }
}
