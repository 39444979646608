import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpConfigInterceptor } from './services/service_interceptor/http-config.interceptor'
import { SharedModule } from './shared/shared.module'
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt'
import { CookieService } from 'ngx-cookie-service';
import { TestpagesComponent } from './testpages/testpages.component';
import { AccueilModule } from './main/accueil/accueil.module'
import { CarouselModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr'
import { ResetPasswordFormComponent } from './connexion/reset-password-form/reset-password-form.component'
import { AddPasswordComponent } from './connexion/add-password/add-password.component';
import { NewHomeModule } from "./main/new-home/new-home.module";

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem('token')
    },
    whitelistedDomains: ['example.com'],
    blacklistedRoutes: ['example.com/examplebadroute/']
  }
}

@NgModule({
  declarations: [AppComponent, TestpagesComponent,ResetPasswordFormComponent,AddPasswordComponent],
  imports: [

    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CarouselModule,
    AccueilModule,
    HttpClientModule,
    NewHomeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
     //Angulartics2GoogleAnalytics,
     CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
