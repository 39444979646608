import { Component, OnInit, Input } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { AuthService } from '@app/services/auth.service'
import { Router, ActivatedRoute} from '@angular/router'

@Component({
  selector: 'mytribu-add-password',
  templateUrl: './add-password.component.html',
  styleUrls: ['./add-password.component.scss']
})
export class AddPasswordComponent implements OnInit {
  @Input() idPass: any
  pass: any = {
    password: '',
    confirmePassword: ''
  }
  errormessage: any = ''
  success: any = ''
  showPassword: any = false
  showConfirmePassword: any = false
  passPattern =
    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'

    constructor(public auth: AuthService, public route: ActivatedRoute,public router: Router) {}
    ngOnInit() {
     /* this.route.paramMap.subscribe((params) => {
        this.idPass = params.get("id") || "";
        console.log("Code lien");
        console.log(this.idPass);
      });*/
    }
  private _unsubscribeAll: Subject<any> = new Subject()

  Reset(pass) {
    let resetpassword: any = {
      password: this.pass.password
    }
    if (this.pass.password != '' && this.pass.confirmePassword != '') {
      this.auth
        .resetPassword(this.idPass, resetpassword)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          res => {
            this.success = 'Mot de passe modifié avec succès.'
            let self = this
            setTimeout(() => {
              self.success = ''
              this.router.navigate(['connexion'])
            }, 5000)
          },
          MsgError => {
            if (
              MsgError.err.error &&
              MsgError.err.error.message == 'Client not found.'
            ) {
              this.errormessage = `le lien a expiré. S'il vous plaît essayer à nouveau.`
            } else if (
              MsgError.err.error &&
              MsgError.err.error.message == 'password exist.'
            ) {
              this.errormessage = `Désolé ! Vous ne pouvez pas réutiliser un ancien mot de passe. Réessayez avec un nouveau`
            }
            this.pass.password = ''
            this.pass.confirmePassword = ''
          }
        )
    } else {
      this.errormessage =
        this.pass.password == ''
          ? 'Veuillez remplir tous les champs requis.'
          : 'Veuillez remplir tous les champs requis.'
    }
  }
}
