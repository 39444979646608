import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { environment } from '../../environments/environment'
import { Observable, throwError, BehaviorSubject } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { EncryptDecryptService } from './encrypt-decrypt.service'
import { Title } from '@angular/platform-browser'
import * as jwt_decode from 'jwt-decode'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: any = environment.url
  loaderpagedisplay = new BehaviorSubject<any>({ show: true })
  pageTitle = new BehaviorSubject<any>([])
  private token = new BehaviorSubject<Object>({})
  constructor(
    public http: HttpClient,
    public jwtHelper: JwtHelperService,
    public crypto: EncryptDecryptService,
    public titleService: Title
  ) {}
  // getPage() {
  //   return this.http.get(this.url + 'user/payment')
  //   // , {responseType: 'text'})
  // }
  setTitle(title) {
    this.titleService.setTitle(title)
  }
  setpageTitle(value) {
    this.pageTitle.next(value)
  }
  getpageTitle() {
    return this.pageTitle.asObservable()
  }
  //loaderpagedisplay
  setloaderpagedisplay(value) {
    this.loaderpagedisplay.next({ show: value })
  }
  getloaderpagedisplay() {
    return this.loaderpagedisplay.asObservable()
  }
  setItem(item, value) {
    localStorage.setItem(
      item,
      this.crypto.encryptData(
        typeof value == 'string' ? value : JSON.stringify(value)
      )
    )
  }

  getItem(item): any {
    let value = localStorage.getItem(item)
    value = value ? this.crypto.decryptData(value) : null
    return value
  }

  // //token validation
  // isAuthenticated(): boolean {
  //   const token = this.getAuth()
  //   return !this.jwtHelper.isTokenExpired(token)
  // }

  //Token config
  getAuth() {
    return localStorage.getItem('token')
  }

  setAuth(value) {
    //0.0138889//this accept day not minuts
    localStorage.setItem('token', value)
    this.token.next(value)
  }

  getToken() {
    return this.token.asObservable()
  }

   //**** Matomo*/
   getMatomo(): Observable<any> {
    return this.http.get(this.url + 'MC/client/integrate/matomo')
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token)

    if (decoded.exp === undefined) return null

    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getAuth()
    if (!token) return true

    const date = this.getTokenExpirationDate(token)
    if (date === undefined) return false
    return !(date.valueOf() > new Date().valueOf())
  }
  deleteAuth(): void {
    return localStorage.removeItem('token')
  }

  //client config
  getClient(): any {
    let client = localStorage.getItem('client')
    return client ? JSON.parse(client) : null
  }

  setClient(value: string): void {
    //0.0138889//this accept day not minuts
    localStorage.setItem('client', JSON.stringify(value))
  }
  deleteClient(): void {
    localStorage.removeItem('client')
  }

  LoginClient(client): Observable<any> {
    let body = client
    return this.http.post(this.url + 'client/login', body)
  }

  RefreshToken(): Observable<any> {
    return this.http.get(this.url + 'client/refresh/token')
  }

  StaticPage(mypage): Observable<any> {

    return this.http.get(this.url + 'pageStatique/' + mypage)
  }  

  StaticPageDynamic(): Observable<any> {
    return this.http.get(this.url + 'pagesstatic/')
  }
  getSlider(): Observable<any> {
    return this.http.get(this.url + 'EC/slideShows')
  }

  responseBanqueManuel(body): Observable<any> {
    return this.http.post(this.url + 'responseBanqueManuel', body)
  }

  resetPassword(code, body): Observable<any> {
    return this.http.put(this.url + 'client/password/reset/' + code, body)
  }
}
