import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginGuard } from './services/authGurds/login.guard'
import { TestpagesComponent } from './testpages/testpages.component'
import { AccueilComponent } from './main/accueil/accueil.component';
import { ResetPasswordFormComponent } from './connexion/reset-password-form/reset-password-form.component';
import { AddPasswordComponent } from './connexion/add-password/add-password.component';
import { NewHomeComponent } from './main/new-home/new-home.component';

const routes: Routes = [
  {
    path: "oldHome",
    component: AccueilComponent,
  },
  {
    path: "",
    component: NewHomeComponent,
  },
  {
    path: "connexion",
    loadChildren: './connexion/connexion.module#ConnexionModule',
    runGuardsAndResolvers: 'always',
    // resolve: { connexion: ConnexionResolverService },
    canActivate: [LoginGuard]
  },
  {
    path: 'inscription',
    loadChildren: './nous-rejoindre/nous-rejoindre.module#NousRejoindreModule',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'home/:id',
    component: TestpagesComponent,
    canActivate: [LoginGuard],
    runGuardsAndResolvers: 'always',
  },
 {
    path: 'resetpassword/:id',
   // loadChildren: './connexion/connexion.module#ConnexionModule',
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
    component: ResetPasswordFormComponent
  },
  {
    path: 'addpassword/:id',
    //loadChildren: './connexion/connexion.module#ConnexionModule',
    runGuardsAndResolvers: 'always',
    component: AddPasswordComponent,
    canActivate: [LoginGuard]
  },


  {
    path: '',
    loadChildren: './main/main.module#MainModule'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule {}
