import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  public startTime: number | null = null;

  constructor(private router: Router) {

    this.trackPageViews();
    // Initialiser gtag si elle est disponible
    if (typeof gtag === 'function') {
      //gtag('config', 'G-90B16E8QQP'); // Remplacez par votre ID GA4
      gtag('config', 'G-90B16E8QQP'); // Remplacez par votre ID GA4
    }
  } 
  // Suivi des pages vues
  /*private trackPageViews() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', { page_path: event.urlAfterRedirects });
        this.startTime = Date.now(); // Démarre le suivi du temps passé sur la page
      }
    });
  }*/
  // Suivi des pages vues avec démarrage du suivi du temps
  private trackPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.startTime) {
          this.trackTimeSpent(); // Suivi du temps passé avant de passer à une autre page
        }
        this.startTime = Date.now(); // Redémarre le suivi pour la nouvelle page
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
  // Suivi des événements de clic
  public trackClick(eventName: string, eventParams: any = {}) {
    gtag('event', eventName, eventParams);
  }

  // Suivi du temps passé sur une page
 /* public trackTimeSpent() {
    if (this.startTime) {
      const timeSpent = Date.now() - this.startTime;
      gtag('event', 'time_spent', { time_spent: timeSpent });
      this.startTime = Date.now(); // Redémarre le compteur pour la nouvelle page
      
    }
  }*/
   // Suivi du temps passé sur une page
   public trackTimeSpent() {
    if (this.startTime) {
      const timeSpent = (Date.now() - this.startTime) / 1000; // Temps en secondes
      gtag('event', 'time_spent', {
        event_category: 'engagement',
        event_label: this.router.url, // Remplacez par un identifiant de page, si disponible
        value: timeSpent,
      });
      this.startTime = null; // Réinitialise le compteur
    }
  }
    // Fonction pour envoyer des événements
  public sendEvent(eventName: string, eventParams: any = {}) {
      if (typeof gtag === 'function') {
        gtag('event', eventName, eventParams); // Utilise les trois arguments requis
      } else {
        console.warn('gtag not defined');
      }
  }



}
